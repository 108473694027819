const apiBase =
    process.env.NODE_ENV === "production"
        ? window.location.hostname
        : "http://localhost:8080";
module.exports = {
    // ports
    serverPort: 8080,
    //This app url
    appURL: apiBase,
    appName: "MYBE Forms",
    appVersionMessage: "Forms Overhaul",
    appVersion: 0.5
};
