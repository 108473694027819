import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { VersionPusherComponent, CookieRequestComponent } from "@unity/components";
import AuthContext from "./services/AuthContext";
import { LinearProgress, Fade } from "@mui/material";

import * as config from "./config";

const FormRoutes = React.lazy(() => import("./routes/FormRoutes"));

export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider value={{ ...props }}>
      <VersionPusherComponent
        name={config.appName} 
        message={config.appVersionMessage}
        number={config.appVersion}
      />
      <CookieRequestComponent/>

      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route
                      path="/forms"
                      component={(route) => (
                        <Suspense fallback={<Fade in={true}><LinearProgress/></Fade>}>
                          <FormRoutes 
                            context={context} 
                            route={route} 
                            history={history} 
                          />
                        </Suspense>
                      )}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  )
}